header {
  padding: 2rem 10rem 1rem 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  form {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    button {
      align-self: flex-start;
    }
  }
}

main {
  margin-top: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 10rem 1rem 0;
  gap: 1rem;

  button {
    align-self: flex-end;
  }

  form {
    display: flex;
    flex-direction: row;
    width: 70rem;
    gap: 1rem;

    button {
      align-self: flex-start;
    }
  }
}

.border {
  border: 1px solid gray;
}

.checkbox {
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}
